// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-executive-committee-js": () => import("./../../../src/pages/executiveCommittee.js" /* webpackChunkName: "component---src-pages-executive-committee-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-words-from-president-js": () => import("./../../../src/pages/wordsFromPresident.js" /* webpackChunkName: "component---src-pages-words-from-president-js" */),
  "component---src-pages-working-committee-js": () => import("./../../../src/pages/workingCommittee.js" /* webpackChunkName: "component---src-pages-working-committee-js" */)
}

